import './App.css';
import Accordion from 'react-bootstrap/Accordion';
import Container from "react-bootstrap/Container";
import ListGroup from 'react-bootstrap/ListGroup';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <div className="App">
        <div style={{marginTop: '3em'}}>
        <Container fluid="lg">
            <header><h2>Chicken Rules</h2></header>
            <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>The Obvious</Accordion.Header>
              <Accordion.Body>
              <ListGroup>
                <ListGroup.Item>No shenanigans during classes, no disturbing instructors</ListGroup.Item>
                <ListGroup.Item>No shenanigans in peoples rooms</ListGroup.Item>
                <ListGroup.Item>No breaking the rules of the event</ListGroup.Item>
                <ListGroup.Item>No breaking the rules of the Dekoeven Center</ListGroup.Item>
                <ListGroup.Item>No breaking State or Federal law</ListGroup.Item>
              </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header>Challenging for a chicken</Accordion.Header>
              <Accordion.Body>
              <ListGroup>
                <ListGroup.Item>Anyone may challenge anyone wearing a chicken</ListGroup.Item>
                <ListGroup.Item>Nobody is obligated to accept a challenge</ListGroup.Item>
                <ListGroup.Item>There are no rules concerning the nature of the challenge</ListGroup.Item>
                <ListGroup.Item>Longsword sparring? Darts? Who can drink a can of beer fastest?</ListGroup.Item>
                <ListGroup.Item>Just don't do anything dumb or break the Obvious rules</ListGroup.Item>
                <ListGroup.Item>Victory is determined based on vibes. Pass them chickens around</ListGroup.Item>
              </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Other Methods</Accordion.Header>
              <Accordion.Body>
              <ListGroup>
                <ListGroup.Item>Chickens may be stolen by stealth or speed</ListGroup.Item>
                <ListGroup.Item>but not in a way that conflicts with the Obvious rules</ListGroup.Item>
                <ListGroup.Item>If a chicken is stolen in this way, no take-backsies applies</ListGroup.Item>
                <ListGroup.Item>Wait at least a few minutes before any attempt to steal it back</ListGroup.Item>
                <ListGroup.Item>If a chicken is stolen in this way, please provide the perpetrator with a lanyard</ListGroup.Item>
              </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header>Treatment of the chickens</Accordion.Header>
              <Accordion.Body>
              <ListGroup>
                <ListGroup.Item>There are no rules concerning the treatment of the chickens</ListGroup.Item>
                <ListGroup.Item>If they break, they break</ListGroup.Item>
                <ListGroup.Item>Spar with them if you want</ListGroup.Item>
                <ListGroup.Item>If a belt hook or twine or lanyard breaks, see Kevin Farthing, he has spares</ListGroup.Item>
              </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Post Event</Accordion.Header>
              <Accordion.Body>
              <ListGroup>
                <ListGroup.Item>This is a dumb game</ListGroup.Item>
                <ListGroup.Item>There is a dumb prize</ListGroup.Item>
                <ListGroup.Item>If you have a chicken on Sunday, contact Kevin Farthing</ListGroup.Item>
                <ListGroup.Item>You'll be asked for a mailing address in exchange for the chicken</ListGroup.Item>
              </ListGroup>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default App;